import { Controller } from 'stimulus';
import anychart from 'anychart';
import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";
const strategies = {
  'AssignedWorkflow': function(e) {
    const id = e.item.get('id');
    let url = `/assigned_workflows/${id}/view_filled_data/`;

    if (isAdmin()) { url = '/admin' + url; }
    $('body').addClass('loading');

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: { assigned_workflow_id: id},
      success: function (data) {
        $('body').removeClass('loading');
      }
    };
  },

  'LabRequest': function(e) {
    const id = e.item.get('labRequestPackId');
    let url = `/lab_request_packs/${id}/edit`;

    if (isAdmin()) { url = `/admin/${url}`; }

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },

  'Task': function(e) {
    const url = e.item.get('url');

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },

  'Appointment': function(e) {
    const id = e.item.get('id');
    let url = `/appointments/${id}/checkin`;

    if (isAdmin()) { url = `/admin/${url}`; }

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },

  'ClinicalNote': function(e) {
    const id = e.item.get('id');
    var patientId = e.item.get('patientId');
    let url = `/patients/${patientId}/clinical_notes/${id}/edit`;

    if (isAdmin()) { url = `/admin/${url}`; }

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },

  'PatientEmail': function(e) {
    const id = e.item.get('id');
    var patientId = e.item.get('patientId');
    let url = `/patients/${patientId}/patient_emails/${id}/edit`;

    if (isAdmin()) { url = `/admin/${url}`; }

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },


  'PatientMasterTreatment': function(e) {
    const id = e.item.get('id');
    var patientId = e.item.get('patientId');
    let url = `/patients/${patientId}/patient_master_treatments/${id}/edit`;

    if (isAdmin()) { url = `/admin/${url}`; }

    return {
      url: url,
      type: 'GET',
      dataType: 'script',
      data: {},
      error: function(result) {
        toastr.error('Something went wrong.');
      }
    };
  },
};
export default class extends ApplicationController {
  static targets = ['']
  static values = {
    data: Array,
  }


  connect() {
    window.vm = this;

    StimulusReflex.register(this);
    $('body')[0].classList.add('mini-navbar');
    this.cleanData();
    this.drawAny();
    this.openPatientProfile();
    this.legendState = {
      'PatientMasterWorkflow': true,
      'Appointments': true,
      'PatientEmail': true,
      'PatientMasterTreatments': true,
      'Tasks': true,
      'Transactions': true,
      'ClinicalNotes': true,
      'AssignedWorkflow': true,
      'LabRequests': true,
      'NonWorkflowItems': true
    };
  }

  openPatientProfile() {
    var vm = this
    console.log('binding a search for gantt..')
    $(".patient-search-select-gantt").on('select2:select select2:unselect', function (e) {
      var id = e.params.data['id']
      var url;
      if(isAdmin())
        url = `/admin/patients/${id}/gantt_view?id=${id}`
      else
        url = `/patients/${id}/gantt_view?id=${id}`

      window.location = url;
    });
  }

  cleanData () {
    var temp = [];
    this.dataValue.forEach((activity) => {
      var t = JSON.parse(activity);
      temp.push(
        t
      );
    })

    this.dataValue = temp;
  }

  drawAny () {
    var vm = this;
    anychart.onDocumentReady(function () {
      var data = vm.latestData = vm.dataValue;

      // create a data tree
      var treeData = anychart.data.tree(data, "as-tree");
      // create a chart
      var chart = anychart.ganttProject();
      vm.setUpChart(chart, treeData, data);
    });
  }

  updateScale (e) {
    $('body').addClass('loading');

    e.preventDefault();

    var movement = e.target.parentElement.dataset['movement']
    if (!movement) {
      movement = e.target.dataset['movement']
    }

    console.log('here..', movement)
    if (movement == '+') {
      vm.scaleOffset -= 1;
    } else {
      vm.scaleOffset += 1;
    }
    var treeData = anychart.data.tree(vm.latestData, "as-tree");

    console.log('offset is:', vm.scaleOffset)
    vm.setUpChart(vm.chart, treeData, vm.latestData);
  }

  setScale(chart) {
    var vm = this;
    var data = vm.latestData;

    var minStartDate = data.reduce((min, activity) => {
      var start = new Date(activity.actualStart);
      return start < min ? start : min;
    }, new Date(data[0].actualStart));

    var maxEndDate = data.reduce((max, activity) => {
        var end = new Date(activity.actualEnd);
        return end > max ? end : max;
    }, new Date(data[0].actualEnd));

    // Calculate the time difference in months
    var diff = (maxEndDate.getFullYear() * 12 + maxEndDate.getMonth()) - (minStartDate.getFullYear() * 12 + minStartDate.getMonth());

    if (!vm.scaleOffset) {
      console.log('setting it here man..', vm.scaleOffset)
      vm.scaleOffset = diff > 12 ? 4 : 2;
    }


    // If overall time period is greater than 1 year, add 4 months. Else add 2 months
    maxEndDate.setMonth(maxEndDate.getMonth() + vm.scaleOffset);

    // Set the maximum value of the scale
    chart.xScale().maximum(maxEndDate);

    chart.fitAll();

    $('body').removeClass('loading')

    return chart;
  }

  setUpChart(chart, treeData, data) {
    var vm = this;
    // vm.latestData = data;
    // set the data
    chart.data(treeData);
    debugger;
    chart = vm.formatColumns(chart);
    chart = vm.formatTooltip(chart);
    vm.formatColumnWorkflows(chart);
    vm.formatLabels(chart);

    // set the container id
    chart.container('gantt');
    // initiate drawing the chart
    chart.draw();
    // fit elements to the width of the timeline
    chart.listen('rowClick', function (e) {
      vm.handleClick(e);
    });

    var header = chart.getTimeline().header();
    // header.fill("#64b5f6 0.2");
    header.fontColor("#475467");
    header.fontWeight(200)
    var tasks = chart.getTimeline().tasks();
    tasks.progress().normal().fill("#0093FF");


    var parentTasks = chart.getTimeline().groupingTasks();
    parentTasks.progress().normal().fill("#0093FF");
    parentTasks.progress().normal().stroke("#0093FF");

    vm.colorCodeWorkflows(chart);
    chart.defaultRowHeight(35);
    chart.splitterPosition("43.5%");
    chart = vm.setScale(chart);
    chart = vm.setButtonStyles(chart);

    var drawingFunction = function () {

      // get the shapes of the element
      var shapes = this["shapes"];
      // get the shape to be modified
      var path = shapes["path"];
      // get the bounds of the element
      var bounds = this["predictedBounds"];

      var h = bounds.height;
      var t = bounds.top;
      var l = bounds.left;
      var r = bounds.left + bounds.width;
      var h1 = bounds.top + bounds.height;
      var h4 = h / 4;
      var h2 = h / 2;

      // draw a rounded rectangle
      path.moveTo(l + h4, h1 - h4)
      path.arcTo(h4, h4, -270, 180)
      path.lineTo(r - h4, t + h4)
      path.arcTo(h4, h4, -90, 180)
      path.lineTo(l + h2, h1 - h4)
      path.close();

    }
    var tasks = chart.getTimeline().tasks();
    var baselines = chart.getTimeline().baselines();

    // // draw custom tasks and baselines
    // tasks.rendering().drawer(drawingFunction);
    // baselines.rendering().drawer(drawingFunction);
    // parentTasks.rendering().drawer(drawingFunction);
    // var periods = chart.getTimeline().periods();

    // // set the height of periods
    // periods.height(40);

    // // draw custom periods
    // periods.rendering().drawer(drawingFunction);

    window.chart = vm.chart = chart;
    $('body').removeClass('loading');
  }

  colorCodeWorkflows (chart) {
    let earliestStart = null;
    let latestEnd = null;
    var treeData =  chart.data();
    var totalDays = 0;
    var ids = [];
    var endPatientJouneryDays = 152;

    // Filtering parent tasks where itemType is 'PatientMasterWorkflow' and addToGantt is true
    var filteredParentTasks = treeData.filter(function(item) {
      return item.get('itemType') === 'PatientMasterWorkflow' && item.get('addToGantt') === true
    });

    filteredParentTasks.forEach(function(item) {
      const start = new Date(item.get('actualStart'));
      const end = new Date(item.get('actualEnd'));
      ids.push(item.get('id'));

      if (!earliestStart || start < earliestStart) {
        earliestStart = start;
      }

      if (!latestEnd || end > latestEnd) {
        latestEnd = end;
      }
    });

    const diffTime = Math.abs(latestEnd - earliestStart);
    totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log('Difference in Days:', totalDays);


    // If the sum is less than 180 - color the tasks progress green else red
    var color = '#48c26e';

    if (totalDays > 152 && totalDays < 213) {
      color = '#FFBF00';
      endPatientJouneryDays = 213;
    } else if (totalDays >= 213) {
      color = '#c90244';
      endPatientJouneryDays = totalDays;
    }

    var parentTasks = chart.getTimeline().groupingTasks();
    parentTasks.progress().normal().fill(function() {
      return color;
    });
    parentTasks.progress().normal().stroke(function() {
      return color;
    });

    chart.getTimeline().tasks().progress().normal().fill(color);
    chart.getTimeline().tasks().progress().normal().stroke(color);

    if (totalDays < 213) {
      totalDays = 223
    }
    // this.drawExpectedPatientTimeline(earliestStart, endPatientJouneryDays, color, chart);
    this.drawExpectedPatientTimeline(earliestStart, 152, '#48c26e', chart, 0, 1);
    this.drawExpectedPatientTimeline(earliestStart, 213, '#FFBF00', chart, 2,3);
    this.drawExpectedPatientTimeline(earliestStart, totalDays, '#c90244', chart,4,5);

  }

  drawExpectedPatientTimeline(startTime, daysOffset, color, chart, m1, m2) {
    var endDate = new Date(startTime);
    startTime = new Date(startTime);
    endDate.setDate(endDate.getDate() + daysOffset);

    // Create two line markers
    // var startMarker = chart.getTimeline().rangeMarker(0);
    // var endMarker = chart.getTimeline().rangeMarker(1);
    // // set the range of the first marker
    // startMarker.from(startTime);
    // startMarker.to(endDate);

    // set the range of the second marker
    // marker_2.from("2018-03-01");
    // marker_2.to("end");

    // set the fill of markers
    // startMarker.fill(`${color} 0.2`);
    // marker_2.fill("#dd2c00 0.2");
    // Set values of markers
    // startMarker.value(startTime);
    // endMarker.value(endDate);
    // console.log(startTime)
    // console.log(endDate)

    // Set the stroke of markers
    // endMarker.stroke(`2 ${color}`);
    // startMarker.stroke(`2 ${color}`);

    // create two line markers
    var marker_1 = chart.getTimeline().lineMarker(m1);
    var marker_2 = chart.getTimeline().lineMarker(m2);

    // set values of markers
    marker_1.value(startTime);
    marker_2.value(endDate);

    // set the stroke of markers
    marker_1.stroke(`2 grey`);
    marker_2.stroke(`2 ${color}`);


    // create two text markers
    marker_1 = chart.getTimeline().textMarker(0);
    marker_2 = chart.getTimeline().textMarker(1);

    // set values of markers
    // marker_1.value(startTime);
    // // marker_2.value(endDate);

    // // // set the text of markers
    // marker_1.text("Treatment Start date");
    // marker_2.text("Treatment expected end date");

    // // configure the font of markers
    // marker_1.fontColor("#455a64");
    // marker_2.fontColor("#dd2c00");
    // marker_1.fontWeight(100);
    // marker_2.fontWeight(100);

    // // configure the background of the first marker
    // marker_1.background().fill("#e3e8e8");
    // marker_1.background().stroke("2 #455a64");

    // // configure the position of markers
    // marker_1.rotation(0);
    // marker_1.padding(5)
    // marker_1.offsetY(10);
    // marker_1.offsetX(55);

    // marker_2.offsetX(-10);

  }


  toggleCategory(event) {
    var vm = this;
    const checkbox = event.target;
    const type = checkbox.id;
    const isChecked = checkbox.checked;

    // Hide or show the category based on isChecked
    this.toggleCategoryInChart(event);
  }

  toggleCategoryInChart(event) {
    var vm = this;
    // Get the type of the clicked legend
    const type = event.target.id;
    console.log('hiding/showing', type);
    var filteredData = [];

    // Toggle the visibility status in the legendState
    this.legendState[type] = !this.legendState[type];
    vm.dataValue.forEach(function(parentObject) {
      if (parentObject.itemType == 'PatientMasterWorkflow') {
        if (vm.legendState['PatientMasterWorkflow'] == false) {
          // debugger;
        } else {
          var child = [];
          parentObject.children.forEach(function(childObject) {
            var childType = childObject.itemType;
            if (!childType) {debugger;}
            console.log('childType', childType)
            if (!childType) {debugger;}
            console.log('encountering:', vm.legendState[childType])
            if (vm.legendState[childType]) {
              console.log('we can push', childType);
              child.push(childObject);
            }
          });
          parentObject.children = child;
          filteredData.push(parentObject);
        }
      } else if (parentObject.itemType == 'Appointments' && vm.legendState['Appointments'] == false) {

      }
      else if (parentObject.itemType == 'NonWorkflowItems' && vm.legendState['NonWorkflowItems'] == false) {
      }

       else {
        // var child = [];
        // parentObject.children.forEach(function(childObject) {
        //   var childType = childObject.itemType;
        //   console.log('childType', childType)
        //   // if (!childType) {debugger;}
        //   console.log('encountering:', vm.legendState[childType])
        //   if (vm.legendState[childType]) {
        //     console.log('we can push', childType);
        //     child.push(childObject);
        //   }
        // });
        // parentObject.children = child;
        filteredData.push(parentObject);
      }
    })

    const treeData = anychart.data.tree(filteredData, "as-tree");
    vm.latestData = filteredData;
    this.setUpChart(this.chart, treeData, this.filteredData);
  }

  formatLabels(chart) {
    function timeDiff(item) {
      var start = new Date(item.get('actualStart'));
      var end = new Date(item.get('actualEnd'));
      var diffDays = Math.ceil(Math.abs(end - start) / (1000 * 60 * 60 * 24));
      var diffMonths = Math.floor(diffDays / 30);
      var diffYears = Math.floor(diffMonths / 12);

      var progress = item.get('progressValue');

      // Remaining days after calculating months and years
      var remainingDays = diffDays % 30;

      // Remaining months after calculating years
      var remainingMonths = diffMonths % 12;

      var timeDiffStr = "";
      if (diffYears > 0) {
        timeDiffStr += `${diffYears} years `;
      }

      if (remainingMonths > 0) {
        timeDiffStr += `${remainingMonths} months `;
      }

      if (remainingDays > 0) {
        timeDiffStr += `${remainingDays} days`;
      }

      return `- <span style='color:#475467 font-size: 12px;'>${progress} (${timeDiffStr.trim()})</span>`;
    }

    var timeline = chart.getTimeline();
    timeline.tasks().labels().useHtml(true);
    timeline.groupingTasks().labels().useHtml(true);

    timeline.tasks().labels().format(function() {
      return timeDiff(this.item);
    });

    // configure labels of parent tasks
    timeline.groupingTasks().labels().format(function() {
      if (this.item.get('itemType') == 'Appointments') {
        return `${this.item.get('totalInterval')}`
      } else {
        return timeDiff(this.item);
      }
    });
  }

  handleClick(e) {
    const itemType = e.item.get('itemType');
    const strategy = strategies[itemType];

    if (strategy) {
      $.ajax(strategy(e));
    }
  }

  formatColumns(chart) {
    var grid = chart.dataGrid();
    grid.column(1).width(230).title({ text: 'Items', fontColor: '#475467' });
    grid.column(2).title({ text: 'Date Time', fontColor: '#475467' });
    grid.column(3).title({ text: 'Assignment', fontColor: '#475467' });
    grid.column(0).width(10)
    grid.column(1).width(300)
    grid.column(2).width(200)
    grid.column(3).width(200)

    grid.column(2).labels().fontColor("#475467");
    grid.column(2).labels().format(
      "{%actualStart}{dateTimeFormat:dd MMM h:mm a} - {%actualEnd}{dateTimeFormat:dd MMM h:mm a}"
      );

    grid.column(3).labels().fontColor("#475467");
    grid.column(3).labels().format(
        "{%assigned_to}"
    );

    return chart;
  }

  formatTooltip(chart) {
    var html = "<span style='font-weight:600;font-size:12pt'>" +
    "{%actualStart}{dateTimeFormat:dd MMM} - " +
    "{%actualEnd}{dateTimeFormat:dd MMM}</span><br><br>" +
    "Status: {%status}<br>" +
    "Assigned To: {%assigned_to}<br>" +
    "Completed By: {%completedBy}"

    chart.dataGrid().tooltip().useHtml(true);
    chart.dataGrid().tooltip().format(html);
    chart.getTimeline().tooltip().useHtml(true);
    chart.getTimeline().tooltip().format(html);

    return chart;
  }

  formatColumnWorkflows(chart) {
    var periodLabels = chart.getTimeline().periods().labels();
    periodLabels.enabled(true);
    periodLabels.useHtml(true);

    chart.dataGrid().column(1).labels().useHtml(true);

    // Main column
    chart.dataGrid().column(1).labels().format(function() {
      var color = this.item.get('color') || '#475467';
      var fontSize = this.item.get('fontSize') || '10px';
      var icon = this.item.get('icon') || '';

      // Apply the 'test-icon-gantt' class to the span element
      return `<span class='icon-span ${icon}' style='color: ${color}; font-size: ${fontSize};'>${this.item.get('name')}</span>`;
    });
  }

  setButtonStyles(chart) {
    var buttons = chart.dataGrid().buttons();

    // configure data grid buttons
    buttons.fontWeight(600);
    buttons.fontSize(26);
    buttons.fontFamily("Courier");
    buttons.background().fill(null);
    buttons.background().stroke(null);
    buttons.width(30);
    buttons.height(30);

    buttons.cursor("default")

    return chart;
  }
}
